export enum PlatformEnum {
	ios = 'ios',
	android = 'android'
}

export enum SortDirectionEnum {
	asc = 'asc',
	desc = 'desc'
}

export enum DataFieldsToSortEnum {
	conceptCreatingDate = 'concept_creating_date',
	releaseDate = 'release_date',
	cpi = 'cpi',
	retention = 'retention',
	marginality = 'morja',
	balance = 'balance_type',
	date = 'date',
	promotion = 'promotion',
	creationDate = 'creationDate',
	documentDate = 'documentDate',
	operationDate = 'operationDate',
	operationType = 'operationType',
	sum = 'sum',
	issueDate = 'issueDate',
	paymentDate = 'paymentDate'
}
