import * as checkins from './checkins'
import * as conceptArts from './conceptArts'
import * as concepts from './concepts'
import * as employees from './employees'
import * as finance from './finance'
import * as financeOperations from './financeOperations'
import * as subscriptions from './subscriptions'
import * as projects from './projects'
import * as promotions from './promotions'
import * as publishers from './publishers'
import * as sicknesses from './sicknesses'
import * as statistics from './statistics'
import * as trainingProjects from './trainingProjects'
import * as vacations from './vacations'
import * as visits from './visits'
import * as ideas from './ideas'
import * as surveys from './surveys'
import * as parties from './parties'
import * as todolists from './todolists'
import * as kpi from './kpi'
import * as notifications from './notifications'

const dataOperations = {
	checkins,
	conceptArts,
	concepts,
	employees,
	finance,
	financeOperations,
	subscriptions,
	projects,
	promotions,
	publishers,
	sicknesses,
	statistics,
	trainingProjects,
	vacations,
	visits,
	ideas,
	surveys,
	parties,
	todolists,
	kpi,
	notifications
}

export default dataOperations
