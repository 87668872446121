import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { InputFormGroup } from 'components/FormGroups';
import { FaEdit } from 'react-icons/fa'
import { Row, Col } from 'react-bootstrap';

import { RolesEnum } from '../../../../../types/roles';
import { useUpdateContractTypeMutation } from '../../../../../redux/slices/financeDictionaries'
import { ContractType } from '../../../../../types/finance/contract'
import UpdateModal from '../../../../../components/Modals/UpdateModal'

interface UpdateContractTypeModalProps {
	contractType: ContractType;
}

const UpdateContractTypeModal = (
	{ contractType }: UpdateContractTypeModalProps
) => {
	const { t } = useTranslation();

	const [enName, setEnName] = useState(contractType.enName || '');
	const [ukName, setUkName] = useState(contractType.ukName || '');

	const [enNameError, setEnNameError] = useState(false);
	const [ukNameError, setUkNameError] = useState(false);

	const [updateContractType] = useUpdateContractTypeMutation();

	const handleUpdateContractType = async () => {
		let isValid = true;

		// Set error flags based on validation
		setUkNameError(!ukName);
		setEnNameError(!enName)

		if (!ukName || !enName) {
			isValid = false;
		}

		if (!isValid) return false;

		const changes = {
			enName,
			ukName
		};

		try {
			await updateContractType({id: contractType._id, changes: changes});
			clearForm();
			return true;
		} catch (error) {
			console.error("Error updating account type:", error);
			return false;
		}
	};

	const clearForm = () => {
		setUkName('');
		setEnName('');
	};

	return (
		<UpdateModal
			id={contractType._id}
			title={'UpdateContractType_title'}
			icon={FaEdit}
			onAccept={handleUpdateContractType}
			requiredRoles={[RolesEnum.admin, RolesEnum.finances]}
		>
			<Row>
				<Col md={12}>
					<InputFormGroup
						name="name"
						label={t('ukName')}
						value={ukName}
						onChange={(e) => {
							setUkName(e.target.value);
							setUkNameError(false);
						}}
						error={ukNameError}
					/>
				</Col>
				<Col md={12}>
					<InputFormGroup
						name="name"
						label={t('enName')}
						value={enName}
						onChange={(e) => {
							setEnName(e.target.value);
							setEnNameError(false);
						}}
						error={enNameError}
					/>
				</Col>
			</Row>
		</UpdateModal>
	);
};

export default UpdateContractTypeModal;
