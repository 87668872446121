import { Pagination } from 'react-bootstrap'
import Select from 'react-select'
import React from 'react'

interface PaginationComponentProps {
	currentPage: number;
	totalPages: number;
	itemsPerPage: number;
	itemsPerPageOptions: number[];
	onPageChange: (page: number) => void;
	onItemsPerPageChange: (itemsPerPage: number) => void;
	dataLength: number;
	pagesDisplayLimit?: number;
	displayTotalPages?: boolean;
}

export function PaginationComponent({
	currentPage,
	totalPages,
	itemsPerPage,
	itemsPerPageOptions,
	onPageChange,
	onItemsPerPageChange,
	dataLength,
	pagesDisplayLimit = 1,
	displayTotalPages = false
}: PaginationComponentProps) {
	const perPageOptions = itemsPerPageOptions.map(el => {
		return { value: el, label: el }
	})

	const displayPages = () => {
		const pages: JSX.Element[] = []

		const addPageItem = (i: number) => (
			<Pagination.Item key={i} active={i === currentPage} onClick={() => onPageChange(i)}>
				{i}
			</Pagination.Item>
		)

		if (totalPages > 3) pages.push(<Pagination.First key="first" onClick={() => onPageChange(1)} disabled={currentPage === 1} />)
		if (totalPages > 1) pages.push(<Pagination.Prev key="prev" onClick={() => onPageChange(currentPage - 1)} disabled={currentPage === 1} />)

		if (totalPages <= 3) {
			for (let i = 1; i <= totalPages; i++) pages.push(addPageItem(i))
		} else {
			if (currentPage > 2) pages.push(<Pagination.Ellipsis key="start-ellipsis" disabled />)
			const startPage = Math.max(1, currentPage - pagesDisplayLimit)
			const endPage = Math.min(totalPages, currentPage + pagesDisplayLimit)
			for (let i = startPage; i <= endPage; i++) pages.push(addPageItem(i))
			if (currentPage < totalPages - 1) pages.push(<Pagination.Ellipsis key="end-ellipsis" disabled />)
		}

		if (totalPages > 1) pages.push(<Pagination.Next key="next" onClick={() => onPageChange(currentPage + 1)} disabled={currentPage === totalPages} />)
		if (totalPages > 3) pages.push(<Pagination.Last key="last" onClick={() => onPageChange(totalPages)} disabled={currentPage === totalPages} />)

		return pages
	}

	const filteredOptions = perPageOptions.filter(option => option.value <= dataLength)

	return (
		<div className="d-flex justify-content-center align-items-center">
			{filteredOptions.length > 1 && (
				<div className="mx-3" style={{ width: 90 }}>
					<Select
						options={filteredOptions}
						value={perPageOptions.find(option => option.value === itemsPerPage)}
						onChange={(selectedOption: any) => onItemsPerPageChange(selectedOption.value)}
						isSearchable={false}
						placeholder="Items"
						menuPlacement="top"
						styles={{
							control: (provided) => ({
								...provided,
								minHeight: '38px',
								height: '38px'
							})
						}}
					/>
				</div>
			)}
			{totalPages > 1 && (
				<Pagination size='lg' className="mb-0 justify-content-center align-items-center">
					{displayPages()}
				</Pagination>
			)}

			{
				displayTotalPages && (
					<span className="ml-2 text-muted">of {totalPages}</span>
				)
			}
		</div>
	)
}