import React, { lazy } from 'react'
import { IconType } from 'react-icons'
import { RolesEnum } from '../types/roles'
import {
	FiBook as BookIcon,
	FiCalendar as CalendarIcon, FiCoffee as CoffeeIcon, FiDollarSign as DollarSignIcon,
	FiHome as HomeIcon,
	FiInbox as InboxIcon, FiPieChart as PieChartIcon,
	FiUserCheck,
	FiUsers as UsersIcon
} from 'react-icons/fi'
import { Outlet, redirect } from 'react-router-dom'
import {
	AccountsTab,
	CashFlowTypesTab,
	ContractorsTab,
	ContractsTab,
	CurrencyRatesTab,
	ExpenseTypesTab,
	OrganizationsTab, ProjectsTab
} from '../pages/dashboards/Finance/Dictionaries'
import { TurnoversReportTab } from '../pages/dashboards/Finance/Reports'

// Dashboards
const MyPage = lazy(() => import('../pages/dashboards/HR/Employees/MyPage'))
const PublisherPage = lazy(() => import('../pages/other/Publishers/PublisherPage'))
const EmployeePage = lazy(() => import('../pages/dashboards/HR/Employees/EmployeePage'))
const SurveyPage = lazy(() => import('../pages/other/Survey'))
const ConceptPage = lazy(() => import('../pages/dashboards/Concepts/ConceptPage'))
const ProjectPage = lazy(() => import('../pages/dashboards/Projects/ProjectPage'))

const Projects = lazy(() => import('../pages/dashboards/Projects'))
const GanttChart = lazy(() => import('../pages/dashboards/GanttChart'))
const EmployeesStatistic = lazy(() => import('../pages/dashboards/Statistic/EmployeesStatistic'))
const Developers = lazy(() => import('../pages/dashboards/ArtistsAndDevelopers/Developers'))
const Artists = lazy(() => import('../pages/dashboards/ArtistsAndDevelopers/Artists'))
const LevelDesigners = lazy(
	() => import('../pages/dashboards/ArtistsAndDevelopers/LevelDesigners')
)
const OtherPositions = lazy(
	() => import('../pages/dashboards/ArtistsAndDevelopers/OtherPositions')
)
const Ideation = lazy(() => import('../pages/dashboards/Ideation'))
const CompanyStatistics = lazy(() => import('../pages/dashboards/Statistic/CompanyStatistic'))
const MentorsStatistic = lazy(() => import('../pages/dashboards/Statistic/MentorsStatistic'))

const StartDayTimeTracking = lazy(() => import('pages/dashboards/HR/TimeTracking/TimeTracking'))
const Publishers = lazy(() => import('../pages/other/Publishers'))

const Employees = lazy(() => import('../pages/dashboards/HR/Employees'))
const Vacation = lazy(() => import('../pages/dashboards/HR/Vacation'))
const Sicknesses = lazy(() => import('../pages/dashboards/HR/Sicknesses'))
const Parties = lazy(() => import('../pages/dashboards/HR/Parties'))
const TimeTrackingPage = lazy(() => import('../pages/dashboards/HR/TimeTracking'))
const Todolists = lazy(() => import('../pages/dashboards/HR/Todolists'))
const KPI = lazy(() => import('../pages/dashboards/HR/KPI'))

const Balance = lazy(() => import('../pages/dashboards/Finance/Balance'))
const Invoices = lazy(() => import('../pages/dashboards/Finance/Invoices'))
const Expenses = lazy(() => import('../pages/dashboards/Finance/Expenses'))
const Payback = lazy(() => import('../pages/dashboards/Finance/Payback'))
const Dividends = lazy(() => import('../pages/dashboards/Finance/Dividends'))
const Salaries = lazy(() => import('../pages/dashboards/Finance/Salaries'))
const Subscriptions = lazy(() => import('../pages/dashboards/Finance/Subscriptions'))
const Operations = lazy(() => import('../pages/dashboards/Finance/Operations'))

const SystemSettings = lazy(() => import('../pages/dashboards/SystemSettings'))

export type RouteItem = {
	path: string
	external_link?: boolean
	name: string
	icon?: IconType
	component?: React.ComponentType<any>
	requiredRoles?: RolesEnum[]
	children?: RouteItem[]
	loader?: any
	displayInSidebar?: boolean
}

// Routes
export const routes: RouteItem[] = [
	{
		path: '/',
		name: 'sideMenu_mainLabel',
		icon: HomeIcon,
		component: MyPage
	},
	{
		path: 'projects',
		name: 'sideMenu_projectsLabel',
		icon: InboxIcon,
		component: Projects,
		requiredRoles: [RolesEnum.base]
	},
	{
		path: 'ganttChart',
		name: 'sideMenu_gantChart',
		icon: CalendarIcon,
		component: GanttChart,
		requiredRoles: [
			RolesEnum.management,
			RolesEnum.qa,
			RolesEnum.hr,
			RolesEnum.gantt_chart,
			RolesEnum.producer_asistant,
			RolesEnum.lead_level_designer,
			RolesEnum.art_lead
		]
	},
	{
		path: 'https://recruiting-system-24play.web.app',
		external_link: true,
		name: 'sideMenu_recruiting',
		icon: FiUserCheck,
		requiredRoles: [
			RolesEnum.hr,
			RolesEnum.recruiting,
			RolesEnum.management,
			RolesEnum.tech_lead
		]
	},
	{
		path: 'HR',
		name: 'sideMenu_HR',
		icon: UsersIcon,
		requiredRoles: [
			RolesEnum.hr,
			RolesEnum.management,
			RolesEnum.art_lead,
			RolesEnum.co_lead,
			RolesEnum.lead_level_designer,
			RolesEnum.producer_asistant
		],
		component: Outlet,
		children: [
			{
				path: '',
				name: 'defaultPagePath',
				loader: () => redirect('employees'),
				displayInSidebar: false
			},
			{
				path: 'employees',
				name: 'sideMenu_employees',
				component: Employees,
				requiredRoles: [RolesEnum.project_manager, RolesEnum.hr, RolesEnum.employees]
			},
			{
				path: 'vacations',
				name: 'sideMenu_vacations',
				component: Vacation,
				requiredRoles: [RolesEnum.hr]
			},
			{
				path: 'sicknesses',
				name: 'sideMenu_sicknesses',
				component: Sicknesses,
				requiredRoles: [RolesEnum.hr]
			},
			{
				path: 'timeTracking',
				name: 'sideMenu_latenesses',
				component: TimeTrackingPage,
				requiredRoles: [RolesEnum.hr]
			},
			{
				path: 'parties',
				name: 'sideMenu_parties',
				component: Parties,
				requiredRoles: [RolesEnum.hr]
			},
			{
				path: 'todolists',
				name: 'sideMenu_todolists',
				component: Todolists,
				requiredRoles: [
					RolesEnum.hr,
					RolesEnum.management,
					RolesEnum.art_lead,
					RolesEnum.co_lead,
					RolesEnum.lead_level_designer,
					RolesEnum.producer_asistant
				]
			},
			{
				path: 'KPI',
				name: 'sideMenu_kpi',
				component: KPI,
				requiredRoles: [RolesEnum.admin]
			}
		]
	},
	{
		path: 'statistic',
		name: 'sideMenu_statistics',
		icon: PieChartIcon,
		requiredRoles: [RolesEnum.management, RolesEnum.hr, RolesEnum.ratings, RolesEnum.finances],
		component: Outlet,
		children: [
			{
				path: '',
				name: 'defaultPagePath',
				loader: () => redirect('company'),
				displayInSidebar: false
			},
			{
				path: 'company',
				name: 'sideMenu_companyStatistic',
				component: CompanyStatistics,
				requiredRoles: [RolesEnum.finances]
			},
			{
				path: 'employees',
				name: 'sideMenu_employeesStatistic',
				component: EmployeesStatistic,
				requiredRoles: [RolesEnum.management, RolesEnum.hr, RolesEnum.ratings]
			},
			{
				path: 'mentors',
				name: 'sideMenu_mentorsStatistic',
				component: MentorsStatistic,
				requiredRoles: [RolesEnum.management, RolesEnum.hr, RolesEnum.ratings]
			}
		]
	},
	{
		path: 'employees',
		name: 'sideMenu_artAndDev',
		icon: CoffeeIcon,
		component: Outlet,
		requiredRoles: [
			RolesEnum.tech_lead,
			RolesEnum.lead_level_designer,
			RolesEnum.project_manager,
			RolesEnum.hr,
			RolesEnum.developers
		],
		children: [
			{
				path: '',
				name: 'defaultPagePath',
				loader: () => redirect('developers'),
				displayInSidebar: false
			},
			{
				path: 'developers',
				name: 'sideMenu_developers',
				component: Developers,
				requiredRoles: [
					RolesEnum.tech_lead,
					RolesEnum.project_manager,
					RolesEnum.hr,
					RolesEnum.developers
				]
			},
			{
				path: 'artists',
				name: 'sideMenu_artists',
				component: Artists,
				requiredRoles: [RolesEnum.art_lead, RolesEnum.project_manager, RolesEnum.hr]
			},
			{
				path: 'level_designers',
				name: 'sideMenu_levelDesigners',
				component: LevelDesigners,
				requiredRoles: [
					RolesEnum.lead_level_designer,
					RolesEnum.project_manager,
					RolesEnum.hr
				]
			},
			{
				path: 'other_positions',
				name: 'sideMenu_otherPositions',
				component: OtherPositions,
				requiredRoles: [RolesEnum.hr]
			},
			{
				path: 'ideation',
				name: 'sideMenu_ideation',
				component: Ideation,
				requiredRoles: [
					RolesEnum.tech_lead,
					RolesEnum.art_lead,
					RolesEnum.project_manager,
					RolesEnum.hr,
					RolesEnum.developers
				]
			}
		]
	},
	{
		path: 'publishers',
		name: 'sideMenu_publishers',
		icon: BookIcon,
		component: Publishers,
		requiredRoles: [RolesEnum.project_manager, RolesEnum.publishers]
	},
	{
		path: 'finances',
		name: 'sideMenu_finances',
		icon: DollarSignIcon,
		component: Outlet,
		requiredRoles: [
			RolesEnum.admin,
			RolesEnum.assistant_manager,
			RolesEnum.finances,
			RolesEnum.hr,
			RolesEnum.invoices,
			RolesEnum.expenses,
			RolesEnum.subscriptions,
			RolesEnum.balances
		],
		children: [
			{
				path: '',
				name: '',
				loader: () => redirect('invoices'),
				displayInSidebar: false
			},
			{
				path: 'operations',
				name: 'sideMenu_operations',
				component: Operations,
				requiredRoles: [
					RolesEnum.admin, RolesEnum.finances
				]
			},
			{
				path: 'dictionaries',
				name: 'sideMenu_dictionaries',
				component: Outlet,
				requiredRoles: [RolesEnum.admin, RolesEnum.finances],
				children: [
					{
						path: '',
						name: 'defaultPagePath',
						loader: () => redirect('currency-rates'),
						displayInSidebar: false
					},
					{
						path: 'currency-rates',
						name: 'currency-rates',
						component: CurrencyRatesTab,
						requiredRoles: [RolesEnum.admin, RolesEnum.finances],
					},
					{
						path: 'cash-flow-types',
						name: 'cash-flow-types',
						component: CashFlowTypesTab,
						requiredRoles: [RolesEnum.admin, RolesEnum.finances],
					},
					{
						path: 'expenses-types',
						name: 'expenses-types',
						component: ExpenseTypesTab,
						requiredRoles: [RolesEnum.admin, RolesEnum.finances],
					},
					{
						path: 'organizations',
						name: 'organizations',
						component: OrganizationsTab,
						requiredRoles: [RolesEnum.admin, RolesEnum.finances],
					},
					{
						path: 'contractors',
						name: 'contractors',
						component: ContractorsTab,
						requiredRoles: [RolesEnum.admin, RolesEnum.finances],
					},
					{
						path: 'accounts',
						name: 'accounts',
						component: AccountsTab,
						requiredRoles: [RolesEnum.admin, RolesEnum.finances],
					},
					{
						path: 'contracts',
						name: 'contracts',
						component: ContractsTab,
						requiredRoles: [RolesEnum.admin, RolesEnum.finances],
					},
					{
						path: 'projects',
						name: 'projects',
						component: ProjectsTab,
						requiredRoles: [RolesEnum.admin, RolesEnum.finances],
					},
				]
			},
			{
				path: 'reports',
				name: 'sideMenu_financeReports',
				component: Outlet,
				requiredRoles: [RolesEnum.admin, RolesEnum.finances],
				children: [
					{
						path: '',
						name: 'defaultPagePath',
						loader: () => redirect('turnover-report'),
						displayInSidebar: false
					},
					{
						path: 'turnover-report',
						name: 'turnover-report',
						component: TurnoversReportTab,
						requiredRoles: [RolesEnum.admin, RolesEnum.finances],
					},
				]
			},
			{
				path: 'invoices',
				name: 'sideMenu_invoices',
				component: Invoices,
				requiredRoles: [RolesEnum.project_manager, RolesEnum.invoices, RolesEnum.finances]
			},
			{
				path: 'balance',
				name: 'sideMenu_accounts',
				component: Balance,
				requiredRoles: [RolesEnum.assistant_manager, RolesEnum.balances, RolesEnum.finances]
			},
			{
				path: 'expenses',
				name: 'sideMenu_expenses',
				component: Expenses,
				requiredRoles: [
					RolesEnum.assistant_manager,
					RolesEnum.hr,
					RolesEnum.expenses,
					RolesEnum.finances
				]
			},
			{
				path: 'salaries',
				name: 'sideMenu_salaries',
				component: Salaries,
				requiredRoles: [RolesEnum.salary, RolesEnum.hr, RolesEnum.finances]
			},
			{
				path: 'profitability',
				name: 'sideMenu_payback',
				component: Payback,
				requiredRoles: [RolesEnum.finances]
			},
			{
				path: 'dividends',
				name: 'sideMenu_dividends',
				component: Dividends,
				requiredRoles: [RolesEnum.admin, RolesEnum.founder, RolesEnum.finances]
			},
			{
				path: 'subscriptions',
				name: 'sideMenu_subscriptions',
				component: Subscriptions,
				requiredRoles: [
					RolesEnum.admin,
					RolesEnum.assistant_manager,
					RolesEnum.hr,
					RolesEnum.subscriptions,
					RolesEnum.finances
				]
			}
		]
	},
	{
		path: 'concept/:id',
		name: 'privateRoutes_сonceptPage',
		component: ConceptPage,
		displayInSidebar: false
	},
	{
		path: 'project/:id',
		name: 'privateRoutes_projectPage',
		component: ProjectPage,
		displayInSidebar: false
	},
	{
		path: 'employee/:id',
		name: 'privateRoutes_еmployeePage',
		component: EmployeePage,
		displayInSidebar: false
	},
	{
		path: 'publisher/:id',
		name: 'privateRoutes_publisherPage',
		component: PublisherPage,
		displayInSidebar: false
	},
	{
		path: 'checkin',
		name: 'Checkin',
		component: StartDayTimeTracking,
		displayInSidebar: false
	},
	{
		path: 'survey',
		name: 'privateRoutes_survey',
		component: SurveyPage,
		displayInSidebar: false
	},
	{
		path: 'system_settings',
		name: 'privateRoutes_adminPanel',
		component: SystemSettings,
		displayInSidebar: false
	},
]