import { useState, useEffect } from 'react';
import useDebounce from './useDebounce'
import { toast } from 'react-toastify'

export function useIsMobile() {
	const [isMobile, setIsMobile] = useState(false);
	const [screenWidth, setScreenWidth] = useState(window.innerWidth);

	const debouncedScreenWidth = useDebounce(screenWidth, 200); // Debounce with 200ms delay

	useEffect(() => {
		function handleResize() {
			setScreenWidth(window.innerWidth);
		}

		// Add resize listener
		window.addEventListener('resize', handleResize);

		return () => {
			// Clean up the listener
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	useEffect(() => {
		function checkIsMobile() {
			const isTouchDevice = 'ontouchstart' in window || navigator.maxTouchPoints > 0;
			const isSmallScreen = debouncedScreenWidth <= 768;
			const isMobileUserAgent = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
			setIsMobile(isTouchDevice && isSmallScreen && isMobileUserAgent);
		}

		// Perform the check whenever debouncedScreenWidth changes
		checkIsMobile();
	}, [debouncedScreenWidth]);

	if (isMobile) {
		// toast.success('From Phone')
	}

	return isMobile;
}

