import { createApi } from '@reduxjs/toolkit/query/react'
import { axiosBaseQuery } from '../../api/axios.queryBuilder'
import { GetGroupedOperationStatementsArgs, ReportResponse } from '../../types/finance/reports'

export const reportsApi = createApi({
	reducerPath: 'reportsApi',
	baseQuery: axiosBaseQuery({
		baseUrl: `finance`,
	}),
	tagTypes: ['ReportsTurnovers'],
	endpoints: (builder) => ({
		getTurnovers: builder.query<
			ReportResponse,
			GetGroupedOperationStatementsArgs
		>({
			query: (args) => ({
				url: `/reports/turnovers`,
				method: 'GET',
				params: args,
			}),
			providesTags: ['ReportsTurnovers'],
		}),
	}),
});


export const { useGetTurnoversQuery } = reportsApi;