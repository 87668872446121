/**
 * Rounds a number to a specified number of decimal places.
 * @param {number} number - The number to round.
 * @param {number} digits - The number of decimal places.
 * @returns {number} The rounded number.
 */
export function roundTo(number: number, digits: number): number {
	if (digits < 0) return number;

	const multiplier = 10 ** digits;
	return Math.round(number * multiplier) / multiplier;
}

/**
 * Formats a number with a thousands separator.
 * @param {number} number - The number to format.
 * @param {string} [separator=','] - The separator to use.
 * @returns {string} The formatted number.
 */
export function formatNumber(number: number, separator: string = ' '): string {
	// Round to two decimals (you can adjust this if needed)
	const [integerPart, fractionPart] = number.toFixed(2).split('.');
	// Format only the integer part:
	const formattedInt = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, separator);
	return `${formattedInt}.${fractionPart}`;
}
