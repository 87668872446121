import React, { useState, useMemo } from 'react';
import { Button, Col, Row, Table } from 'react-bootstrap';
import { FaChevronRight } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import RangeCalendar from '../../../../../components/RangeCalendar';
import { MultiselectFormGroup, SingleselectFormGroup } from '../../../../../components/FormGroups';
import { formatNumber } from '../../../../../utils/number';
import { currencySign } from '../../../../../types/finance/currency';
import { ReportNode, ReportResponse } from '../../../../../types/finance/reports';

import {
	useLoadAccountsSelectQuery,
	useLoadCashFlowTypesListQuery,
	useLoadContractorsSelectQuery,
	useLoadOrganizationsSelectQuery,
} from '../../../../../redux/slices/financeDictionaries';
import { useGetTurnoversQuery } from '../../../../../redux/slices/reports';
import Loader from '../../../../../components/Loader'

// Constants
const MANAGEMENT_CURRENCY_NAME = 'USD';
const NATIVE_CURRENCY_NAME = 'UAH';

/**
 * RowItem: Renders a single node (and recursively children).
 * Depending on mode, the "native" columns will show either:
 *  - .openingRaw, .incomingRaw, etc. if in "accounts" mode
 *  - .openingNative, .incomingNative, etc. if in "types" mode
 */
function RowItem({
	node,
	level,
	showNative,
	isAccountsMode,
}: {
	node: ReportNode;
	level: number;
	showNative: boolean;         // short vs full toggle
	isAccountsMode: boolean;     // 'accounts' vs 'types'
}) {
	const [isOpen, setIsOpen] = useState(false);
	const hasChildren = node.children.length > 0;

	// For indentation
	const indentStyle = {
		paddingLeft: `${20 * level}px`,
		width: '500px',
		minWidth: '500px',
		maxWidth: '500px'
	};

	// The "native" displayed values differ by mode
	// If isAccountsMode: we show .openingRaw, .incomingRaw, etc.
	// If !isAccountsMode: we show .openingNative, etc.
	const openingNativeVal = isAccountsMode
		? (node.openingRaw ?? 0)
		: node.openingNative;
	const incomingNativeVal = isAccountsMode
		? (node.incomingRaw ?? 0)
		: node.incomingNative;
	const outgoingNativeVal = isAccountsMode
		? (node.outgoingRaw ?? 0)
		: node.outgoingNative;
	const closingNativeVal = isAccountsMode
		? (node.closingRaw ?? 0)
		: node.closingNative;

	// For mgm columns, it's always the .openingMgm, etc. fields
	const closingMgm = node.openingMgm + node.incomingMgm - node.outgoingMgm;

	const bgColor = `rgba(157,203,255,0.5)`

	return (
		<>
			<tr
				className={`${hasChildren ? 'cursor-pointer' : ''}`}
				style={level === 0 ? {backgroundColor: bgColor} : {}}
				onClick={() => hasChildren && setIsOpen(!isOpen)}
			>
				<td className="text-break text-wrap align-middle" style={indentStyle}>
					{hasChildren && (
						<FaChevronRight
							size={12}
							className="me-1 mx-1"
							style={{
								transition: 'transform 0.2s',
								transform: isOpen ? 'rotate(90deg)' : 'rotate(0deg)',
							}}
						/>
					)}
					{node.name}
				</td>

				{showNative && (
					<>
						<td className="text-right text-nowrap align-middle">
							{formatNumber(openingNativeVal)}{' '}
							{node.currency && currencySign(node.currency)}
						</td>
						<td className="text-right text-nowrap align-middle">
							{formatNumber(incomingNativeVal)}{' '}
							{node.currency && currencySign(node.currency)}
						</td>
						<td className="text-right text-nowrap align-middle">
							{formatNumber(outgoingNativeVal)}{' '}
							{node.currency && currencySign(node.currency)}
						</td>
						<td className="text-right text-nowrap align-middle">
							{formatNumber(closingNativeVal)}{' '}
							{node.currency && currencySign(node.currency)}
						</td>
					</>
				)}

				<td className="text-right text-nowrap align-middle">
					{formatNumber(node.openingMgm)} {currencySign(MANAGEMENT_CURRENCY_NAME)}
				</td>
				<td className="text-right text-nowrap align-middle">
					{formatNumber(node.incomingMgm)} {currencySign(MANAGEMENT_CURRENCY_NAME)}
				</td>
				<td className="text-right text-nowrap align-middle">
					{formatNumber(node.outgoingMgm)} {currencySign(MANAGEMENT_CURRENCY_NAME)}
				</td>
				<td className="text-right text-nowrap align-middle">
					{formatNumber(closingMgm)} {currencySign(MANAGEMENT_CURRENCY_NAME)}
				</td>
			</tr>

			{isOpen &&
				node.children.map(child => (
					<RowItem
						key={child.id}
						node={child}
						level={level + 1}
						showNative={showNative}
						isAccountsMode={isAccountsMode}
					/>
				))}
		</>
	);
}

/**
 * TurnoversTable:
 *  - Renders the table headings
 *  - Renders rows via RowItem
 *  - Renders totals row
 * Depending on mode, the "native" columns are either account-currency (raw) or system native (UAH).
 */
function TurnoversTable({
	tree,
	totals,
	showNative,
	isAccountsMode,
}: {
	tree: ReportNode[];
	totals: ReportResponse['totals'];
	showNative: boolean;
	isAccountsMode: boolean;
}) {
	const { t } = useTranslation();

	return (
		<Table bordered hover responsive className="table-striped table-sm mt-3">
			<thead>
			<tr>
				<th
					rowSpan={2}
					className="align-middle text-break text-wrap"
					style={{
						width: '500px',
						minWidth: '500px',
						maxWidth: '500px',
					}}
				>
					{`${t('account')} / ${t('cashFlowType')}`}
				</th>

				{showNative && (
					<th colSpan={4} className="text-center">
						{isAccountsMode
							? t('accountCurrency')
							: t('systemNative')
						}
					</th>
				)}

				<th colSpan={4} className="text-center">
					{t('managementCurrency')}
				</th>
			</tr>
			<tr>
				{showNative && (
					<>
						<th className="text-center">{t('openingBalance')}</th>
						<th className="text-center">{t('incoming')}</th>
						<th className="text-center">{t('outcoming')}</th>
						<th className="text-center">{t('closingBalance')}</th>
					</>
				)}
				<th className="text-center">{t('openingBalance')}</th>
				<th className="text-center">{t('incoming')}</th>
				<th className="text-center">{t('outcoming')}</th>
				<th className="text-center">{t('closingBalance')}</th>
			</tr>
			</thead>

			<tbody>
			{tree.map(node => (
				<RowItem
					key={node.id}
					node={node}
					level={0}
					showNative={showNative}
					isAccountsMode={isAccountsMode}
				/>
			))}

			<tr className="fw-bold bg-warning">
				<td>{t('total')}</td>

				{showNative && (
					<>
						<td className="text-right text-nowrap">
							{formatNumber(totals.openingNativeConv)}{' '}
							{currencySign(NATIVE_CURRENCY_NAME)}
						</td>
						<td className="text-right text-nowrap">
							{formatNumber(totals.incomingNativeConv)}{' '}
							{currencySign(NATIVE_CURRENCY_NAME)}
						</td>
						<td className="text-right text-nowrap">
							{formatNumber(totals.outgoingNativeConv)}{' '}
							{currencySign(NATIVE_CURRENCY_NAME)}
						</td>
						<td className="text-right text-nowrap">
							{formatNumber(totals.closingNativeConv)}{' '}
							{currencySign(NATIVE_CURRENCY_NAME)}
						</td>
					</>
				)}

				<td className="text-right text-nowrap">
					{formatNumber(totals.openingMgm)} {currencySign(MANAGEMENT_CURRENCY_NAME)}
				</td>
				<td className="text-right text-nowrap">
					{formatNumber(totals.incomingMgm)} {currencySign(MANAGEMENT_CURRENCY_NAME)}
				</td>
				<td className="text-right text-nowrap">
					{formatNumber(totals.outgoingMgm)} {currencySign(MANAGEMENT_CURRENCY_NAME)}
				</td>
				<td className="text-right text-nowrap">
					{formatNumber(totals.closingMgm)} {currencySign(MANAGEMENT_CURRENCY_NAME)}
				</td>
			</tr>
			</tbody>
		</Table>
	);
}

/**
 * TurnoversReport: Container that:
 *  - Provides filters
 *  - Toggles: (viewMode = 'accounts'|'types') and (showNative)
 *  - Renders <TurnoversTable>
 */
export default function TurnoversReport() {
	const { t } = useTranslation();

	const { data: accounts = [] } = useLoadAccountsSelectQuery();
	const { data: cfTypes = [] } = useLoadCashFlowTypesListQuery();
	const { data: contractors = [] } = useLoadContractorsSelectQuery();
	const { data: organizations = [] } = useLoadOrganizationsSelectQuery();

	const [documentDateFrom, setDocumentDateFrom] = useState<Date>(moment('01.01.2023', 'DD.MM.YYYY').toDate());
	const [documentDateTo, setDocumentDateTo] = useState<Date>(moment().startOf('day').endOf('day').toDate());

	const [showNative, setShowNative] = useState(true);

	const [viewMode, setViewMode] = useState<'accounts' | 'types'>('accounts');

	const [selectedOrganizations, setSelectedOrganizations] = useState<string[]>([]);
	const [selectedContractors, setSelectedContractors] = useState<string[]>([]);
	const [selectedCashFlowTypes, setSelectedCashFlowTypes] = useState<string[]>([]);
	const [selectedAccounts, setSelectedAccounts] = useState<string[]>([]);

	const ranges = [
		{ value: '1d', label: 'for_one_day', index: 0 },
		{ value: '1w', label: 'for_one_week', index: 1 },
		{ value: '1m', label: 'for_one_month', index: 2 },
		{ value: '1y', label: 'for_one_year', index: 3 },
		{ value: 'hy', label: 'for_half_year', index: 4 },
		{ value: 'at', label: 'for_all_time', index: 5 },
	];

	const handleQuickSelect = (option: { value: string; label: string }) => {
		const today = moment().startOf('day');
		let newFrom: Date;
		let newTo: Date;

		switch (option.value) {
			case '1d':
				newFrom = today.clone().subtract(1, 'days').toDate();
				newTo = today.endOf('day').toDate();
				break;
			case '1w':
				newFrom = today.clone().subtract(1, 'weeks').toDate();
				newTo = today.endOf('day').toDate();
				break;
			case '1m':
				newFrom = today.clone().subtract(1, 'months').toDate();
				newTo = today.endOf('day').toDate();
				break;
			case '1y':
				newFrom = today.clone().subtract(1, 'years').toDate();
				newTo = today.endOf('day').toDate();
				break;
			case 'hy':
				newFrom = today.clone().subtract(6, 'months').toDate();
				newTo = today.endOf('day').toDate();
				break;
			case 'at':
				newFrom = moment('01.01.2023', 'DD.MM.YYYY').toDate();
				newTo = today.endOf('day').toDate();
				break;
			default:
				newFrom = moment().startOf('month').toDate();
				newTo = moment().endOf('month').toDate();
				break;
		}

		setDocumentDateFrom(newFrom);
		setDocumentDateTo(newTo);
	};

	const validFrom = documentDateFrom || moment().startOf('year').toDate();
	const validTo = documentDateTo || moment().endOf('year').toDate();

	const filters = useMemo(() => {
		return {
			startDate: validFrom.toISOString(),
			endDate: validTo.toISOString(),
			accounts: selectedAccounts.length ? selectedAccounts : undefined,
			organizations: selectedOrganizations.length ? selectedOrganizations : undefined,
			contractors: selectedContractors.length ? selectedContractors : undefined,
			cashFlowTypes: selectedCashFlowTypes.length ? selectedCashFlowTypes : undefined,
			mode: viewMode,
		};
	}, [
		validFrom,
		validTo,
		selectedAccounts,
		selectedOrganizations,
		selectedContractors,
		selectedCashFlowTypes,
		viewMode,
	]);

	const { data: reportData, isLoading, isFetching, isError } = useGetTurnoversQuery(filters);

	const finalData: ReportResponse = reportData || {
		tree: [],
		totals: {
			openingNativeConv: 0,
			incomingNativeConv: 0,
			outgoingNativeConv: 0,
			closingNativeConv: 0,
			openingMgm: 0,
			incomingMgm: 0,
			outgoingMgm: 0,
			closingMgm: 0,
		},
	};

	return (
		<>
			<Row className="g-3">
				<Col xs={12} sm={6} lg={4} xxl={3}>
					<RangeCalendar
						startDate={documentDateFrom}
						endDate={documentDateTo}
						withSelect
						withFullWidth
						onChange={([start, end]) => {
							setDocumentDateFrom(start!);
							setDocumentDateTo(end!);
						}}
					/>
				</Col>

				<Col xs={12} sm={6} lg={4} xxl={3} className="d-flex flex-column justify-content-end">
					<SingleselectFormGroup
						placeholder=""
						options={ranges.map(r => ({ ...r, label: t(r.label) }))}
						defaultValue={{ ...ranges[5], label: t(ranges[5].label) }}
						onChange={val => val && handleQuickSelect(val)}
						isClearable={false}
						error={false}
					/>
				</Col>
			</Row>

			<Row className="g-3 mt-3">
				<Col xs={12} sm={6} lg={4} xxl={3} className="d-flex flex-column justify-content-end">
					<MultiselectFormGroup
						placeholder={t('accounts')}
						options={accounts.map((acc: any, index: number) => ({
							value: acc._id,
							label: acc.name,
							index,
						}))}
						onChange={selected => setSelectedAccounts(selected.map(s => s.value))}
					/>
				</Col>

				<Col xs={12} sm={6} lg={4} xxl={3} className="d-flex flex-column justify-content-end">
					<MultiselectFormGroup
						placeholder={t('organizations')}
						options={organizations.map((org: any, index: number) => ({
							value: org._id,
							label: org.name,
							index,
						}))}
						onChange={selected => setSelectedOrganizations(selected.map(s => s.value))}
					/>
				</Col>

				<Col xs={12} sm={6} lg={4} xxl={3} className="d-flex flex-column justify-content-end">
					<MultiselectFormGroup
						placeholder={t('contractors')}
						options={contractors.map((ctr: any, index: number) => ({
							value: ctr._id,
							label: ctr.name,
							index,
						}))}
						onChange={selected => setSelectedContractors(selected.map(s => s.value))}
					/>
				</Col>

				<Col xs={12} sm={6} lg={4} xxl={3} className="d-flex flex-column justify-content-end">
					<MultiselectFormGroup
						placeholder={t('cashFlowType')}
						options={cfTypes.map((cf: any, index: number) => ({
							value: cf._id,
							label: cf.ukName || cf.enName,
							index,
						}))}
						onChange={selected => setSelectedCashFlowTypes(selected.map(s => s.value))}
					/>
				</Col>
			</Row>

			<div className="d-flex justify-content-between align-items-center mt-3">
				<div>
					{`${ t('sort_by')}:`}
					<Button
						variant={viewMode === 'accounts' ? 'primary' : 'outline-primary'}
						onClick={() => setViewMode(m => (m === 'accounts' ? 'types' : 'accounts'))}
						className='mx-2 shadow-none'
					>
						{t('by_accounts')}
					</Button>
					<Button
						variant={viewMode === 'accounts' ? 'outline-primary' : 'primary'}
						onClick={() => setViewMode(m => (m === 'accounts' ? 'types' : 'accounts'))}
						className='shadow-none'
					>
						{t('by_cashflow_type')}
					</Button>
				</div>

				<div>
					<Button
						variant={showNative ? 'primary' : 'outline-primary'}
						onClick={() => setShowNative(prev => !prev)}
						className='mr-2 shadow-none'
					>
						{t('full_report')}
					</Button>
					<Button
						variant={showNative ? 'outline-primary': 'primary'}
						onClick={() => setShowNative(prev => !prev)}
						className='shadow-none'
					>
						{t('short_report')}
					</Button>
				</div>
			</div>

			{isLoading || isFetching ? (
				<Loader/>
			) : isError ? (
				<div className="text-center my-3">
					<p>{t('An error occurred while fetching report data.')}</p>
				</div>
			) : finalData.tree.length > 0 ? (
				<TurnoversTable
					tree={finalData.tree}
					totals={finalData.totals}
					showNative={showNative}
					isAccountsMode={viewMode === 'accounts'}
				/>
			) : (
				<p className="text-center my-3">{t('No report data found for the selected filters.')}</p>
			)}
		</>
	);
}
