import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../../api/axios.queryBuilder';
import { SystemSettings } from '../../types/systemSettings';

export const systemSettingsApi = createApi({
	reducerPath: 'systemSettingsApi',
	baseQuery: axiosBaseQuery({
		baseUrl: 'system_settings',
	}),
	tagTypes: ['SystemSettings'],
	endpoints: (builder) => ({
		loadSystemSettings: builder.query<SystemSettings, void>({
			query: () => ({
				url: '/all',
				method: 'GET',
			}),
			providesTags: ['SystemSettings'],
		}),
		updateSystemSettings: builder.mutation<SystemSettings, Partial<SystemSettings>>({
			query: (updatingData) => ({
				url: '/update',
				method: 'PUT',
				data: updatingData,
			}),
			invalidatesTags: ['SystemSettings'],
		}),
	}),
});

export const {
	useLoadSystemSettingsQuery,
	useUpdateSystemSettingsMutation,
} = systemSettingsApi;