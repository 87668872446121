import React from 'react'
import UniversalTable from 'components/Tables/UniversalTable'


import 'moment/locale/ru'
import 'moment/locale/uk'
import { useLoadOrganizationsQuery } from '../../../../../redux/slices/financeDictionaries'
import TableError from '../../../../../components/TableError'
import Loader from '../../../../../components/Loader'

const columns = [
    {
        dataField: 'order_number',
        text: '№',
        sort: false,
        width: '5%',
        className: 'text-center'
    },
    {
        dataField: 'name',
        text: 'name',
        sort: false,
        width: '15%',
        className: 'text-center'
    },
    {
        dataField: 'nameEng',
        text: 'name_eng',
        sort: false,
        width: '20%',
        className: 'text-center'
    },
    {
        dataField: 'edrpou',
        text: 'edrpou',
        sort: false,
        width: '15%',
        className: 'text-center'
    },
    {
        dataField: 'registrationaddress',
        text: 'registration_address',
        sort: false,
        width: '30%',
        className: 'text-center'
    },
    {
        dataField: 'vatNumber',
        text: 'vat_number',
        sort: false,
        width: '15%',
        className: 'text-center'
    }
]

interface OrganizationRowProps {
    organization: any;
    index: number;
}

function OrganizationRow({ organization, index } : OrganizationRowProps) {
    if (!organization) return null

    function withDefaultValue(field: string) {
        return field && field.length > 0 ? field : '-'
    }

    return (
        <tr>
            <td className="text-center">{index + 1}</td>
            <td className="text-center">{withDefaultValue(organization.name)}</td>
            <td className="text-center">{withDefaultValue(organization.nameEng)}</td>
            <td className="text-center">{withDefaultValue(organization.edrpou)}</td>
            <td className="text-center">{withDefaultValue(organization.registrationAddress)}</td>
            <td className="text-center">{withDefaultValue(organization.vatNumber)} </td>
        </tr>
    )
}

function CurrencyRatesTable() {
    const { data: organizations = [], isLoading, isError } = useLoadOrganizationsQuery();

    if (isLoading) {
        return <Loader />;
    }

    if (isError) {
        return <TableError>Error loading organizations data.</TableError>;
    }

    return organizations && organizations.length > 0 ?
        <UniversalTable
            name="OrganizationTable_tableName"
            id="organizations"
            columns={columns}
            data={organizations}
            row={(organization, i) => <OrganizationRow index={i} key={i + 'organizationRow'} organization={organization} />}
            scrollable
        /> : <TableError>No organizations data.</TableError>
}

export default CurrencyRatesTable
