import { IconType } from 'react-icons'
import { FaDollarSign, FaEuroSign, FaHryvniaSign } from 'react-icons/fa6'

export interface Currency {
	_id: string;
	name: string;
	code: number;
}

export interface CurrencyRate {
	name: string;
	rate: number;
	date: string; //DateISO
	firstCurrency: string;
	secondCurrency: string;
}

export enum CurrencyType {
	dollar = 'dollar',
	euro = 'euro',
	hryvnia = 'hryvnia'
}

export const currencySign = (currency?: CurrencyType | string) => {
	if (!currency) {
		return '';
	}

	// Map string currency codes to symbols
	const currencyMap: Record<string, string> = {
		USD: '$',
		UAH: '₴',
		EUR: '€',
		'671f694338789befe1556712': '$',
		'671f694338789befe155670e': '₴',
		'671f694338789befe1556710': '€',
	};

	if (typeof currency === 'string') {
		return currencyMap[currency] || ''
	}

	// Handle CurrencyType enum
	switch (currency) {
		case CurrencyType.dollar:
			return '$';
		case CurrencyType.euro:
			return '€';
		case CurrencyType.hryvnia:
			return '₴';
		default:
			return '';
	}
};

export const currencyIconMap: Record<string, IconType> = {
	'$': FaDollarSign,
	'€': FaEuroSign,
	'₴': FaHryvniaSign,
	'671f694338789befe1556712': FaDollarSign,
	'671f694338789befe155670e': FaHryvniaSign,
	'671f694338789befe1556710': FaEuroSign,
};