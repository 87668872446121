import * as types from '../../constants'
import { Founder } from 'types/founder'
import FinanceOperation, { CountTypeEnum, OperationTypeEnum } from 'types/financeOperation'
import EmployeeProfitability from 'types/employeeProfitability'
import ArtAndDevInMonthRow from 'types/artAndDevInMonthRow'
import ProjectInMonthRow from 'types/projectsInMonthRow'
import Expenses from 'types/expense'

export type FinancesState = {
	loading: boolean

	artAndDevInMonth: ArtAndDevInMonthRow[]
	projectsInMonth: ProjectInMonthRow[]
	employeesProfitability: EmployeeProfitability[]
	generalExpenses: Expenses | null

	operations: FinanceOperation[]
	yearOperations: FinanceOperation[]
	balanceOperations: FinanceOperation[]
	advanceOperations: FinanceOperation[]
	dividends: FinanceOperation[]
	founders: Founder[]
}

const initialState: FinancesState = {
	loading: false,

	artAndDevInMonth: [],
	projectsInMonth: [],
	employeesProfitability: [],
	generalExpenses: null,
	operations: [],
	yearOperations: [],
	balanceOperations: [],
	advanceOperations: [],
	dividends: [],
	founders: [],
}

export default function reducer(state = initialState, actions) {
	switch (actions.type) {
		case types.SET_DEFAULT_STATE:
			return initialState

		case types.LOAD_FINANCE_OPERATIONS_BY_MONTH_REQUEST:
		case types.LOAD_ADVANCE_OPERATIONS_BY_MONTH_REQUEST:
		case types.LOAD_FINANCE_OPERATIONS_BY_YEAR_REQUEST:
		case types.LOAD_DIVIDENDS_REQUEST:
		case types.CREATE_FINANCE_OPERATION_REQUEST:
		case types.APPROVE_FINANCE_OPERATION_REQUEST:
		case types.REJECT_FINANCE_OPERATION_REQUEST:
		case types.CHECK_FINANCE_OPERATION_REQUEST:
		case types.DELETE_FINANCE_OPERATION_REQUEST:
		case types.UPLOAD_EXPENSE_FILE_REQUEST:
		case types.DOWNLOAD_EXPENSE_FILE_REQUEST:
		case types.DELETE_EXPENSE_FILE_REQUEST:
		case types.WITHDRAW_DIVIDENDS_REQUEST:
		case types.DISTRIBUTE_FOUNDERS_DIVIDENDS_REQUEST:
		case types.FINANCE_IN_MONTH_RECALCULATE_REQUEST:
		case types.FINANCE_IN_MONTH_REQUEST:
			return {
				...state,
				loading: true
			}

		//success
		case types.FINANCE_IN_MONTH_RECALCULATE_SUCCESS:
		case types.FINANCE_IN_MONTH_SUCCESS:
			return {
				...state,
				loading: false,
				...actions.payload
			}

		case types.FINANCE_IN_MONTH_RECALCULATE_ERROR:
		case types.FINANCE_IN_MONTH_ERROR:
			return {
				...state,
				loading: false
			}

		case types.LOAD_FINANCE_OPERATIONS_BY_MONTH_SUCCESS:
			return {
				...state,
				loading: false,
				...actions.payload
			}

		case types.LOAD_FINANCE_OPERATIONS_BY_YEAR_SUCCESS:
			return {
				...state,
				loading: false,
				yearOperations: actions.payload
			}

		case types.CREATE_FINANCE_OPERATION_SUCCESS:
			const operation = actions.payload

			let updatedBalances = {}

			if (operation.type === OperationTypeEnum.other_expense) {
				const calculator = () => {
					switch (operation.count_type) {
						case CountTypeEnum.HR_EXPENSE:
							return {
								hrBalance: operation.count_balance_after,
								[`${operation.responsible_position}HrBalance`]:
									operation.position_balance_after
							}

						case CountTypeEnum.OFFICE_EXPENSE:
							return {
								officeBalance: operation.count_balance_after,
								[`${operation.responsible_position}OfficeBalance`]:
									operation.position_balance_after
							}

						case CountTypeEnum.OTHER_EXPENSE:
							return {
								otherBalance: operation.count_balance_after,
								[`${operation.responsible_position}OtherBalance`]:
									operation.position_balance_after
							}

						default:
							return {}
					}
				}

				updatedBalances = calculator()
			}

			return {
				...state,
				loading: false,
				operations: [operation, ...state.operations],
				...updatedBalances
			}

		case types.APPROVE_FINANCE_OPERATION_SUCCESS:
		case types.REJECT_FINANCE_OPERATION_SUCCESS:
			return {
				...state,
				loading: false,
				balanceOperations: state.balanceOperations.map((operation: FinanceOperation) => {
					if (operation._id === actions.payload._id) {
						return actions.payload
					}
					return operation
				})
			}

		case types.CHECK_FINANCE_OPERATION_SUCCESS:
			const newBalances = () => {
				switch (actions.payload.count_type) {
					case CountTypeEnum.HR_EXPENSE:
						return {
							hrBalance: actions.payload.count_balance_after,
							[`${actions.payload.responsible_position}HrBalance`]:
								actions.payload.position_balance_after
						}

					case CountTypeEnum.OFFICE_EXPENSE:
						return {
							officeBalance: actions.payload.count_balance_after,
							[`${actions.payload.responsible_position}OfficeBalance`]:
								actions.payload.position_balance_after
						}

					case CountTypeEnum.OTHER_EXPENSE:
						return {
							otherBalance: actions.payload.count_balance_after,
							[`${actions.payload.responsible_position}OtherBalance`]:
								actions.payload.position_balance_after
						}

					default:
						return {}
				}
			}

			return {
				...state,
				loading: false,
				operations: state.operations.map((operation: FinanceOperation) => {
					if (operation._id === actions.payload._id) {
						return actions.payload
					}
					return operation
				}),
				...newBalances()
			}

		//expense file

		case types.UPLOAD_EXPENSE_FILE_SUCCESS:
			return {
				...state,
				loading: false,
				operations: state.operations.map((operation: FinanceOperation) => {
					if (operation._id === actions.payload._id) {
						return actions.payload
					}
					return operation
				})
			}

		case types.DOWNLOAD_EXPENSE_FILE_SUCCESS:
			return {
				...state,
				loading: false
			}

		case types.DELETE_EXPENSE_FILE_SUCCESS:
			return {
				...state,
				loading: false,
				operations: state.operations.map((operation: FinanceOperation) => {
					if (operation._id === actions.payload._id) {
						return actions.payload
					}
					return operation
				})
			}

		//Balance
		//Dividends

		case types.LOAD_DIVIDENDS_SUCCESS:
			return {
				...state,
				loading: false,
				...actions.payload
			}

		case types.DISTRIBUTE_FOUNDERS_DIVIDENDS_SUCCESS:
			return {
				...state,
				loading: false,
				founders: actions.payload
			}

		//Advance operations
		case types.LOAD_ADVANCE_OPERATIONS_BY_MONTH_SUCCESS:
			return {
				...state,
				loading: false,
				advanceOperations: actions.payload
			}

		case types.LOAD_FINANCE_OPERATIONS_BY_MONTH_ERROR:
		case types.LOAD_ADVANCE_OPERATIONS_BY_MONTH_ERROR:
		case types.LOAD_FINANCE_OPERATIONS_BY_YEAR_ERROR:
		case types.CREATE_FINANCE_OPERATION_ERROR:
		case types.APPROVE_FINANCE_OPERATION_ERROR:
		case types.REJECT_FINANCE_OPERATION_ERROR:
		case types.CHECK_FINANCE_OPERATION_ERROR:
		case types.DELETE_FINANCE_OPERATION_ERROR:
		case types.UPLOAD_EXPENSE_FILE_ERROR:
		case types.DOWNLOAD_EXPENSE_FILE_ERROR:
		case types.DELETE_EXPENSE_FILE_ERROR:
		case types.LOAD_DIVIDENDS_ERROR:
		case types.DISTRIBUTE_FOUNDERS_DIVIDENDS_ERROR:
		case types.WITHDRAW_DIVIDENDS_ERROR:
			return {
				...state,
				loading: false
			}

		default:
			return state
	}
}
