import { createApi } from '@reduxjs/toolkit/query/react';
import { Currency, CurrencyRate } from '../../types/finance/currency'
import { CashFlowType } from "../../types/finance/cashFlowType";
import { axiosBaseQuery } from "../../api/axios.queryBuilder";
import { ExpenseType } from "../../types/finance/expenseType";
import { Organization, OrganizationSelect } from '../../types/finance/organization'
import { Account, AccountSelect, AddAccountDto } from '../../types/finance/account'
import { AccountType } from '../../types/finance/accountType'
import {
    AddContractorDto,
    Contractor,
    ContractorClass, ContractorSelect,
    ContractorType, CreateContractorClassDto,
    CreateContractorTypeDto, UpdateContractorClassDto, UpdateContractorTypeDto
} from '../../types/finance/contractor'
import {
    AddContractDto,
    Contract, ContractSelect,
    ContractType,
    CreateContractTypeDto,
    UpdateContractTypeDto
} from '../../types/finance/contract'

export const financeDictionariesApi = createApi({
    reducerPath: 'financeApi',
    baseQuery: axiosBaseQuery(
        {
            baseUrl: `finance`
        }
    ),
    tagTypes: [
        'CurrencyRatesToday', 'CurrencyRatesRange', 'CashFlowTypes',
        'ExpenseTypes', 'Organizations',
        'Accounts',
        'Contractors', 'ContractorClasses', 'ContractorTypes',
        'Contract', 'ContractTypes'
    ],
    endpoints: (builder) => ({
        loadCurrenciesForToday: builder.query<CurrencyRate[], void>({
            query: () => ({ url: '/rates/today', method: 'GET' }),
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled;

                    // Only trigger force fetch if data is empty
                    if (!data?.length) {
                        // Attempt force fetch and check for success
                        const result = await dispatch(financeDictionariesApi.endpoints.forceFetchCurrenciesForToday.initiate()).unwrap();

                        // Only invalidate cache if force fetch succeeded and new data was loaded
                        if (result?.success) {
                            dispatch(financeDictionariesApi.util.invalidateTags(['CurrencyRatesToday']));
                        }
                    }
                } catch (error: any) {
                    throw error
                }
            },
            providesTags: ['CurrencyRatesToday'],
        }),
        loadCurrenciesInRange: builder.query<CurrencyRate[], { startDate: string; endDate: string }>({
            query: ({ startDate, endDate }) => ({
                url: `/currencyRates`,
                method: 'GET',
                params: { start: startDate, end: endDate },
            }),
            providesTags: ['CurrencyRatesRange'],
        }),
        forceFetchCurrenciesForToday: builder.mutation<{ success: boolean }, void>({
            query: () => ({ url: '/rates/fetch', method: 'POST' }),
            invalidatesTags: ['CurrencyRatesRange'],
        }),
        loadCurrencies: builder.query<Currency[], void>({
            query: () => ({
                url: `/list`,
                method: 'GET',
            }),
            providesTags: ['CurrencyRatesRange'],
        }),

        loadCashFlowTypes: builder.query<CashFlowType[], void>({
            query: () => ({ url: '/getCashFlowTypes', method: 'GET' }),
            providesTags: ['CashFlowTypes'],
        }),
        updateCashFlowTypes: builder.mutation<void, CashFlowType[]>({
            query: (cashFlowTypes) => ({
                url: '/updateCashFlowTypes',
                method: 'PUT',
                data: cashFlowTypes,
            }),
            invalidatesTags: ['CashFlowTypes'],
        }),
        loadCashFlowTypesList: builder.query<CashFlowType[], void>({
            query: () => ({ url: '/getCashFlowTypesList', method: 'GET' }),
            providesTags: ['CashFlowTypes'],
        }),
        loadCashFlowTypesLowestList: builder.query<CashFlowType[], void>({
            query: () => ({ url: '/getLowestLevelCashFlowTypes', method: 'GET' }),
            providesTags: ['CashFlowTypes'],
        }),

        loadExpenseTypes: builder.query<ExpenseType[], void>({
            query: () => ({ url: '/getExpenseTypes', method: 'GET' }),
            providesTags: ['ExpenseTypes'],
        }),
        updateExpenseTypes: builder.mutation<void, ExpenseType[]>({
            query: (expenseTypes) => ({
                url: '/updateExpenseTypes',
                method: 'PUT',
                data: expenseTypes,
            }),
            invalidatesTags: ['ExpenseTypes'],
        }),
        loadExpenseTypesList: builder.query<ExpenseType[], void>({
            query: () => ({ url: '/getExpenseTypesList', method: 'GET' }),
            providesTags: ['ExpenseTypes'],
        }),
        loadExpenseTypesLowestList: builder.query<ExpenseType[], void>({
            query: () => ({ url: '/getLowestLevelExpenseTypes', method: 'GET' }),
            providesTags: ['ExpenseTypes'],
        }),

        loadOrganizations: builder.query<Organization[], void>({
            query: () => ({ url: '/organizations', method: 'GET' }),
            providesTags: ['Organizations'],
        }),
        loadOrganizationsSelect: builder.query<OrganizationSelect[], void>({
            query: () => ({ url: '/organizationsSelect', method: 'GET' }),
            providesTags: ['Organizations'],
        }),

        loadAccounts: builder.query<Account[], void>({
            query: () => ({ url: '/accounts', method: 'GET' }),
            providesTags: ['Accounts'],
        }),
        loadAccountsSelect: builder.query<AccountSelect[], void>({
            query: () => ({ url: '/accounts/accountsSelect', method: 'GET' }),
            providesTags: ['Accounts'],
        }),
        loadAccountTypes: builder.query<AccountType[], void>({
            query: () => ({ url: '/accounts/types/list', method: 'GET' }),
            providesTags: ['Accounts'],
        }),
        addAccount: builder.mutation<Account, AddAccountDto>({
            query: (newAccount) => ({
                url: '/accounts',
                method: 'POST',
                data: newAccount,
            }),
            invalidatesTags: ['Accounts'],
        }),
        updateAccount: builder.mutation<Account, { id: string; changes: Partial<AddAccountDto> }>({
            query: ({ id, changes }) => ({
                url: `/accounts/${id}`,
                method: 'PATCH',
                data: changes,
            }),
            invalidatesTags: ['Accounts'],
        }),
        updateAccountSum: builder.mutation<Account, { id: string; sum: number }>({
            query: ({ id, sum }) => ({
                url: `/accounts/${id}/sum`,
                method: 'PATCH',
                data: { sum },
            }),
            invalidatesTags: ['Accounts'],
        }),
        archiveAccount: builder.mutation<Account, string>({
            query: (id) => ({ url: `/accounts/${id}/archive`, method: 'PATCH' }),
            invalidatesTags: ['Accounts'],
        }),
        setDefaultAccount: builder.mutation<Account, string>({
            query: (id) => ({ url: `/accounts/${id}/default`, method: 'PATCH' }),
            invalidatesTags: ['Accounts'],
        }),
        unarchiveAccount: builder.mutation<Account, string>({
            query: (id) => ({ url: `/accounts/${id}/unarchive`, method: 'PATCH' }),
            invalidatesTags: ['Accounts'],
        }),
        deleteAccount: builder.mutation<void, string>({
            query: (id) => ({ url: `/accounts/${id}`, method: 'DELETE' }),
            invalidatesTags: ['Accounts'],
        }),

        // Contractor
        loadContractors: builder.query<Contractor[], void>({
            query: () => ({ url: '/contractors', method: 'GET' }),
            providesTags: ['Contractors'],
        }),
        loadContractorsSelect: builder.query<ContractorSelect[], void>({
            query: () => ({ url: '/contractors/contractorsSelect', method: 'GET' }),
            providesTags: ['Contractors'],
        }),
        loadContractorById: builder.query<Contractor, string>({
            query: (id) => ({ url: `/contractors/${id}`, method: 'GET' }),
            providesTags: ['Contractors'],
        }),
        addContractor: builder.mutation<Contractor, AddContractorDto>({
            query: (newContractor) => ({
                url: '/contractors',
                method: 'POST',
                data: newContractor,
            }),
            invalidatesTags: ['Contractors'],
        }),
        updateContractor: builder.mutation<Contractor, { id: string; changes: Partial<AddContractorDto> }>({
            query: ({ id, changes }) => ({
                url: `/contractors/${id}`,
                method: 'PATCH',
                data: changes,
            }),
            invalidatesTags: ['Contractors'],
        }),
        archiveContractor: builder.mutation<Contractor, string>({
            query: (id) => ({ url: `/contractors/${id}/archive`, method: 'PATCH' }),
            invalidatesTags: ['Contractors'],
        }),
        unarchiveContractor: builder.mutation<Contractor, string>({
            query: (id) => ({ url: `/contractors/${id}/unarchive`, method: 'PATCH' }),
            invalidatesTags: ['Contractors'],
        }),
        deleteContractor: builder.mutation<void, string>({
            query: (id) => ({ url: `/contractors/${id}`, method: 'DELETE' }),
            invalidatesTags: ['Contractors'],
        }),

        // Contractor Classes Endpoints
        getContractorClasses: builder.query<ContractorClass[], void>({
            query: () => ({ url: '/contractors/classes/list', method: 'get' }),
            providesTags: ['ContractorClasses'],
        }),
        getUnarchivedContractorClasses: builder.query<ContractorClass[], void>({
            query: () => ({ url: '/contractors/classes/listUnarchived', method: 'get' }),
            providesTags: ['ContractorClasses'],
        }),
        createContractorClass: builder.mutation<ContractorClass, CreateContractorClassDto>({
            query: (newClass) => ({ url: '/contractors/classes', method: 'post', data: newClass }),
            invalidatesTags: ['ContractorClasses'],
        }),
        updateContractorClass: builder.mutation<ContractorClass, { id: string, changes: Partial<UpdateContractorClassDto> }>({
            query: ({ id, changes }) => ({ url: `/contractors/classes/${id}`, method: 'patch', data: changes }),
            invalidatesTags: ['ContractorClasses'],
        }),
        archiveContractorClass: builder.mutation<ContractorClass, string>({
            query: (id) => ({ url: `/contractors/classes/${id}/archive`, method: 'patch' }),
            invalidatesTags: ['ContractorClasses'],
        }),
        unarchiveContractorClass: builder.mutation<ContractorClass, string>({
            query: (id) => ({ url: `/contractors/classes/${id}/unarchive`, method: 'patch' }),
            invalidatesTags: ['ContractorClasses'],
        }),

        // Contractor Types Endpoints
        getContractorTypes: builder.query<ContractorType[], void>({
            query: () => ({ url: '/contractors/types/list', method: 'get' }),
            providesTags: ['ContractorTypes'],
        }),
        getUnarchivedContractorTypes: builder.query<ContractorType[], void>({
            query: () => ({ url: '/contractors/types/listUnarchived', method: 'get' }),
            providesTags: ['ContractorTypes'],
        }),
        createContractorType: builder.mutation<ContractorType, CreateContractorTypeDto>({
            query: (newType) => ({ url: '/contractors/types', method: 'post', data: newType }),
            invalidatesTags: ['ContractorTypes'],
        }),
        updateContractorType: builder.mutation<ContractorType, { id: string, changes: Partial<UpdateContractorTypeDto>}>({
            query: ({ id, changes }) => ({ url: `/contractors/types/${id}`, method: 'patch', data: changes }),
            invalidatesTags: ['ContractorTypes'],
        }),
        archiveContractorType: builder.mutation<ContractorType, string>({
            query: (id) => ({ url: `/contractors/types/${id}/archive`, method: 'patch' }),
            invalidatesTags: ['ContractorTypes'],
        }),
        unarchiveContractorType: builder.mutation<ContractorType, string>({
            query: (id) => ({ url: `/contractors/types/${id}/unarchive`, method: 'patch' }),
            invalidatesTags:  ['ContractorTypes'],
        }),

        // Contract
        loadContracts: builder.query<Contract[], void>({
            query: () => ({ url: '/contracts', method: 'GET' }),
            providesTags: ['Contract'],
        }),
        loadContractsSelect: builder.query<ContractSelect[], void>({
            query: () => ({ url: '/contracts/contractsSelect', method: 'GET' }),
            providesTags: ['Contract'],
        }),
        loadContractsById: builder.query<Contract, string>({
            query: (id) => ({ url: `/contracts/${id}`, method: 'GET' }),
            providesTags: ['Contract'],
        }),
        addContracts: builder.mutation<Contract, AddContractDto>({
            query: (newContract) => ({
                url: '/contracts',
                method: 'POST',
                data: newContract,
            }),
            invalidatesTags: ['Contract'],
        }),
        updateContracts: builder.mutation<Contract, { id: string; changes: Partial<AddContractDto> }>({
            query: ({ id, changes }) => ({
                url: `/contracts/${id}`,
                method: 'PATCH',
                data: changes,
            }),
            invalidatesTags: ['Contract'],
        }),
        archiveContracts: builder.mutation<Contract, string>({
            query: (id) => ({ url: `/contracts/${id}/archive`, method: 'PATCH' }),
            invalidatesTags: ['Contract'],
        }),
        unarchiveContracts: builder.mutation<Contract, string>({
            query: (id) => ({ url: `/contracts/${id}/unarchive`, method: 'PATCH' }),
            invalidatesTags: ['Contract'],
        }),
        deleteContracts: builder.mutation<void, string>({
            query: (id) => ({ url: `/contracts/${id}`, method: 'DELETE' }),
            invalidatesTags: ['Contract'],
        }),

        // Contract Types
        getContractTypes: builder.query<ContractType[], void>({
            query: () => ({ url: '/contracts/types/list', method: 'get' }),
            providesTags: ['ContractTypes'],
        }),
        getUnarchivedContractTypes: builder.query<ContractType[], void>({
            query: () => ({ url: '/contracts/types/listUnarchived', method: 'get' }),
            providesTags: ['ContractTypes'],
        }),
        createContractType: builder.mutation<ContractType, CreateContractTypeDto>({
            query: (newType) => ({ url: '/contracts/types', method: 'post', data: newType }),
            invalidatesTags: ['ContractTypes'],
        }),
        updateContractType: builder.mutation<ContractType, { id: string, changes: Partial<UpdateContractTypeDto>}>({
            query: ({ id, changes }) => ({ url: `/contracts/types/${id}`, method: 'patch', data: changes }),
            invalidatesTags: ['ContractTypes'],
        }),
        archiveContractType: builder.mutation<ContractType, string>({
            query: (id) => ({ url: `/contracts/types/${id}/archive`, method: 'patch' }),
            invalidatesTags: ['ContractTypes'],
        }),
        unarchiveContractType: builder.mutation<ContractType, string>({
            query: (id) => ({ url: `/contracts/types/${id}/unarchive`, method: 'patch' }),
            invalidatesTags:  ['ContractTypes'],
        }),
    })
});

export const {
    useLoadCurrenciesForTodayQuery,
    useLoadCurrenciesInRangeQuery,
    useLoadCurrenciesQuery,

    useLoadCashFlowTypesQuery,
    useUpdateCashFlowTypesMutation,
    useLoadCashFlowTypesListQuery,
    useLoadCashFlowTypesLowestListQuery,

    useLoadExpenseTypesQuery,
    useUpdateExpenseTypesMutation,
    useLoadExpenseTypesListQuery,
    useLoadExpenseTypesLowestListQuery,

    useLoadOrganizationsQuery,
    useLoadOrganizationsSelectQuery,

    useLoadAccountsQuery,
    useLoadAccountsSelectQuery,
    useLoadAccountTypesQuery,
    useAddAccountMutation,
    useUpdateAccountSumMutation,
    useSetDefaultAccountMutation,
    useUpdateAccountMutation,
    useArchiveAccountMutation,
    useUnarchiveAccountMutation,
    useDeleteAccountMutation,

    // Contractor
    useLoadContractorsQuery,
    useLoadContractorsSelectQuery,
    useAddContractorMutation,
    useUpdateContractorMutation,
    useDeleteContractorMutation,
    useArchiveContractorMutation,
    useUnarchiveContractorMutation,

    // Contractor Classes Hooks
    useGetContractorClassesQuery,
    useGetUnarchivedContractorClassesQuery,
    useCreateContractorClassMutation,
    useUpdateContractorClassMutation,
    useArchiveContractorClassMutation,
    useUnarchiveContractorClassMutation,

    // Contractor Types Hooks
    useGetContractorTypesQuery,
    useGetUnarchivedContractorTypesQuery,
    useCreateContractorTypeMutation,
    useUpdateContractorTypeMutation,
    useArchiveContractorTypeMutation,
    useUnarchiveContractorTypeMutation,

    // Contract
    useLoadContractsQuery,
    useLoadContractsSelectQuery,
    useAddContractsMutation,
    useUpdateContractsMutation,
    useDeleteContractsMutation,
    useArchiveContractsMutation,
    useUnarchiveContractsMutation,

    // Contract Types
    useGetContractTypesQuery,
    useGetUnarchivedContractTypesQuery,
    useCreateContractTypeMutation,
    useUpdateContractTypeMutation,
    useArchiveContractTypeMutation,
    useUnarchiveContractTypeMutation,
} = financeDictionariesApi;