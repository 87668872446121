import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { MdCheckBox, MdCheckBoxOutlineBlank } from 'react-icons/md'
import React from 'react'

interface EditCheckboxProps {
	checked: boolean
	title: string
	onCheck: () => void
}

export default function EditCheckbox({ checked, title, onCheck }:EditCheckboxProps) {
	const renderTooltip = (content: string) => (props: any) => (
		<Tooltip id={`tooltip-${Math.random()}`} className={'custom-tooltip top'} {...props}>
			{content}
		</Tooltip>
	);

	return checked ? (
		<Button
			variant="outline-success"
			size="sm"
			className="d-flex align-items-center justify-content-center p-0 ml-2"
			style={{ width: "28px", height: "28px", borderRadius: "4px" }}
			disabled={true}
			aria-label={title}
		>
			<MdCheckBox size={28} />
		</Button>
	) : (
		<OverlayTrigger
			placement="top"
			overlay={renderTooltip(title)}
		>
			<Button
				variant="outline-primary"
				size="sm"
				className="d-flex align-items-center justify-content-center p-0 ml-2"
				style={{ width: "28px", height: "28px", borderRadius: "4px" }}
				onClick={onCheck}
				aria-label={title}
			>
				<MdCheckBoxOutlineBlank size={28} />
			</Button>
		</OverlayTrigger>
	)
}