import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { InputFormGroup } from 'components/FormGroups';
import { FaEdit } from 'react-icons/fa'
import { Row, Col } from 'react-bootstrap';

import { RolesEnum } from '../../../../../types/roles';
import { useUpdateContractorTypeMutation } from '../../../../../redux/slices/financeDictionaries'
import { ContractorType } from '../../../../../types/finance/contractor'
import UpdateModal from '../../../../../components/Modals/UpdateModal'

interface UpdateContractorTypeModalProps {
	contractorType: ContractorType;
}

const UpdateContractorTypeModal = (
	{ contractorType }: UpdateContractorTypeModalProps
) => {
	const { t } = useTranslation();

	const [enName, setEnName] = useState(contractorType.enName || '');
	const [ukName, setUkName] = useState(contractorType.ukName || '');

	const [enNameError, setEnNameError] = useState(false);
	const [ukNameError, setUkNameError] = useState(false);

	const [updateContractorType] = useUpdateContractorTypeMutation();

	const handleUpdateContractorType = async () => {
		let isValid = true;

		// Set error flags based on validation
		setUkNameError(!ukName);
		setEnNameError(!enName)

		if (!ukName || !enName) {
			isValid = false;
		}

		if (!isValid) return false;

		const changes = {
			enName,
			ukName
		};

		try {
			await updateContractorType({id: contractorType._id, changes: changes});
			clearForm();
			return true;
		} catch (error) {
			console.error("Error updating account type:", error);
			return false;
		}
	};

	const clearForm = () => {
		setUkName('');
		setEnName('');
	};

	return (
		<UpdateModal
			id={contractorType._id}
			title={'UpdateContractorType_title'}
			icon={FaEdit}
			onAccept={handleUpdateContractorType}
			requiredRoles={[RolesEnum.admin, RolesEnum.finances]}
		>
			<Row>
				<Col md={12}>
					<InputFormGroup
						name="name"
						label={t('ukName')}
						value={ukName}
						onChange={(e) => {
							setUkName(e.target.value);
							setUkNameError(false);
						}}
						error={ukNameError}
					/>
				</Col>
				<Col md={12}>
					<InputFormGroup
						name="name"
						label={t('enName')}
						value={enName}
						onChange={(e) => {
							setEnName(e.target.value);
							setEnNameError(false);
						}}
						error={enNameError}
					/>
				</Col>
			</Row>
		</UpdateModal>
	);
};

export default UpdateContractorTypeModal;
