import { BalanceTypeEnum, CalcBalances } from './balance'
import { ShortEmployee } from './employee'
import { Founder } from './founder'
import { PositionsEnum } from './positions'
import { CurrencyType } from './finance/currency'

export enum OperationTypeEnum {
	income = 'income',
	request = 'request',
	approved = 'approved',
	rejected = 'rejected',
	expense = 'expense',
	other_expense = 'other_expense',
	inter_balance_transaction = 'inter_balance_transaction'
}
export enum CountTypeEnum {
	INVOICE_INCOME = 'FinanceOperationsDB_invoiceIncome',
	DIRECT_EXPENSE = 'FinanceOperationsDB_directExpense',
	HR_EXPENSE = 'FinanceOperationsDB_hrExpense',
	OFFICE_EXPENSE = 'FinanceOperationsDB_officeExpense',
	OTHER_EXPENSE = 'FinanceOperationsDB_otherExpense'
}

export enum DirectExpenseEnum {
	DIVIDENDS_DIRECT_EXPENSE = 'FinanceOperationsDB_dividendsExpense',
	SALARY_EXPENSE = 'FinanceOperationsDB_salaryExpense',
	BONUSES_EXPENSE = 'FinanceOperationsDB_bonusesExpense',
	OFFICE_RENT_DIRECT_EXPENSE = 'FinanceOperationsDB_officeRentExpense',
	OFFICE_UTILITIES_DIRECT_EXPENSE = 'FinanceOperationsDB_officeUtilitiesExpense',
	DEV_OPS_EXPENSE = 'FinanceOperationsDB_devOpsExpense',
	SUBSCRIPTIONS_EXPENSE = 'FinanceOperationsDB_subscriptionsExpense',
	MARKETING_EXPENSE = 'FinanceOperationsDB_marketingExpense',
	ACCOUNTING_EXPENSE = 'FinanceOperationsDB_accountingExpense',
	TAX_AND_COMMISION_EXPENSE = 'FinanceOperationsDB_taxAndComissionExpense',
	LOAN_EXPENSES = 'FinanceOperationsDB_loanExpenses'
}

export enum OtherExpenseEnum {
	OFFICE_SERVICES_EXPENSE = 'FinanceOperationsDB_officeServicesExpense',
	HARDWARE_EXPENSE = 'FinanceOperationsDB_hardwareExpense',
	OFFICE_EQUIPMENT = 'FinanceOperationsDB_officeEquipment',
	SOFTWARE_EXPENSE = 'FinanceOperationsDB_softwareExpense',
	LEGAL_SERVICES_EXPENSE = 'FinanceOperationsDB_legalServicesExpense',
	CORPORATE_EVENTS_EXPENSE = 'FinanceOperationsDB_corporateEventsExpense',
	MERCH_EXPENSE = 'FinanceOperationsDB_merchExpense',
	ASSETS_DIRECT_EXPENSE = 'FinanceOperationsDB_assetsExpense',
	MASSAGE_EXPENSE = 'FinanceOperationsDB_massageExpense'
}

export interface Balances {
	operations: FinanceOperation[]
	balances: CalcBalances
	hrBalance: number
	officeBalance: number
	otherBalance: number
	recruiterHrBalance: number
	recruiterOfficeBalance: number
	recruiterOtherBalance: number
	hrHrBalance: number
	hrOfficeBalance: number
	hrOtherBalance: number
	office_managerHrBalance: number
	office_managerOfficeBalance: number
	office_managerOtherBalance: number
}

export interface DividendsInfo {
	balances: CalcBalances
	founders: Founder[]
	dividends: DividendsFinananceOperation[]
}

export default interface FinanceOperation {
	_id: string
	date: Date
	type: OperationTypeEnum
	count_type: CountTypeEnum
	category?: DirectExpenseEnum | OtherExpenseEnum
	summ: number
	corrected_summ?: number
	tax?: number
	currency?: CurrencyType
	balance_type?: BalanceTypeEnum
	operation_author: ShortEmployee
	responsible: ShortEmployee
	responsible_position: PositionsEnum
	comment: string
	balance_before?: number
	balance_after?: number
	count_balance_before?: number
	count_balance_after?: number
	position_balance_before?: number
	position_balance_after?: number
	file_id?: string
	file_name?: string
}

export interface DividendsFinananceOperation {
	_id: string
	date: Date
	type: OperationTypeEnum
	count_type: CountTypeEnum
	category?: DirectExpenseEnum | OtherExpenseEnum
	summ: number
	corrected_summ?: number
	tax?: number
	currency?: CurrencyType
	operation_author: string
	responsible: string
	responsible_position: PositionsEnum
	comment: string
	balance_before?: number
	balance_after?: number
	count_balance_before?: number
	count_balance_after?: number
	position_balance_before?: number
	position_balance_after?: number
	file_id?: string
	file_name?: string
}

export interface FinanceOperationCreationData {
	date: Date
	type: OperationTypeEnum
	count_type: CountTypeEnum
	summ: number
	operation_author: string
	responsible: string
	responsible_position: PositionsEnum
	comment: string

	category?: DirectExpenseEnum | OtherExpenseEnum
	corrected_summ?: number
	tax?: number
	currency?: CurrencyType
	balance_type?: BalanceTypeEnum
	balance_before?: number
	balance_after?: number
	count_balance_before?: number
	count_balance_after?: number
	position_balance_before?: number
	position_balance_after?: number

	advance?: boolean
}

export interface FinanceOperationCheckData {
	_id: string
	corrected_summ: number
	tax: number
	currency: CurrencyType
	balance_type: BalanceTypeEnum
}
