import React from 'react'
import { useTranslation } from 'react-i18next';

import UniversalTable from 'components/Tables/UniversalTable';

import {
	useArchiveContractorMutation,
	useDeleteContractorMutation, useLoadContractorsQuery,
	useUnarchiveContractorMutation
} from '../../../../../redux/slices/financeDictionaries'
import { FaArchive, FaTrashAlt } from 'react-icons/fa'
import { FaArrowRotateLeft } from 'react-icons/fa6'
import UpdateContractorModal from '../Modals/UpdateContractorModal'
import { RolesEnum } from '../../../../../types/roles'
import { Contractor } from '../../../../../types/finance/contractor'
import TableError from '../../../../../components/TableError'
import DangerModal from '../../../../../components/Modals/DangerModal'
import SuccessModal from '../../../../../components/Modals/SuccessModal'
import Loader from '../../../../../components/Loader'

const columns = [
	{
		dataField: 'name',
		text: 'ContractorsTable_name',
		sort: false,
		width: '20%',
		className: 'text-center',
	},
	{
		dataField: 'type',
		text: 'ContractorsTable_Type',
		sort: false,
		width: '10%',
		className: 'text-center',
	},
	{
		dataField: 'class',
		text: 'ContractorsTable_Class',
		sort: false,
		width: '10%',
		className: 'text-center',
	},
	{
		dataField: 'fullName',
		text: 'ContractorsTable_fullName',
		sort: false,
		width: '20%',
		className: 'text-center',
	},
	{
		dataField: 'taxCode',
		text: 'ContractorsTable_taxCode',
		sort: false,
		width: '10%',
		className: 'text-center',
	},
	{
		dataField: 'address',
		text: 'ContractorsTable_registrationAddress',
		sort: false,
		width: '20%',
		className: 'text-center',
	},
	{
		dataField: 'actions',
		text: 'actions',
		sort: false,
		width: '10%',
		className: 'text-center',
	}
];

interface ContractorRowProps {
	contractor: Contractor;
	handlers: any
}

function ContractorRow({ contractor, handlers }: ContractorRowProps) {
	const { t, i18n } = useTranslation();

	const [archiveContractor, unarchiveContractor, deleteContractor] = handlers

	return (
		<tr>
			<td className="text-center">{contractor.name}</td>
			<td className="text-center">{(i18n.language === 'uk' ? contractor.type.ukName : contractor.type.enName)}</td>
			<td className="text-center">{(i18n.language === 'uk' ? contractor.class.ukName : contractor.class.enName)}</td>
			<td className="text-center">{contractor.fullName}</td>
			<td className="text-center">{contractor.taxCode}</td>
			<td className="text-center">{contractor.registrationAddress}</td>
			<td className="text-center">
				<div className='d-flex align-middle justify-content-center'>
					{
						!contractor.isArchived && (
							<UpdateContractorModal contractor={contractor} />
						)
					}
					{
						contractor.isArchived ? (
							<SuccessModal
								id={contractor._id}
								title={t('unarchive')}
								icon={FaArrowRotateLeft}
								onAccept={() => unarchiveContractor(contractor._id)}
								tooltipText={'unarchive'}
								requiredRoles={[RolesEnum.admin, RolesEnum.finances]}
							/>
						) : (
							<DangerModal
								id={contractor._id}
								title={t('archive')}
								onAccept={() => archiveContractor(contractor._id)}
								icon={FaArchive}
								tooltipText={'archive'}
								requiredRoles={[RolesEnum.admin, RolesEnum.finances]}
							/>
						)
					}
					{
						contractor.isArchived && (
							<DangerModal
								id={contractor._id}
								title="DeleteContractorModal_title"
								onAccept={() => deleteContractor(contractor._id)}
								icon={FaTrashAlt}
								tooltipText={'delete'}
								requiredRoles={[RolesEnum.admin, RolesEnum.finances]}
							/>
						)
					}
				</div>
			</td>
		</tr>
	);
}

function ContractorsTable() {
	const [archiveContractor] = useArchiveContractorMutation()
	const [unarchiveContractor] = useUnarchiveContractorMutation()
	const [deleteContractor] = useDeleteContractorMutation()

	const { data: contractorData, isLoading, isError } = useLoadContractorsQuery();

	if (isLoading) return <Loader />;
	if (isError) return <TableError>Error loading data</TableError>;

	const unarchivedContractorData = (contractorData && contractorData.filter(entry => !entry.isArchived)) || []
	const archivedContractorData = (contractorData && contractorData.filter(entry => entry.isArchived)) || []

	const handlers = [archiveContractor, unarchiveContractor, deleteContractor]

	return <>
		{
			unarchivedContractorData && unarchivedContractorData.length > 0 ?
				<UniversalTable
					name={'ContractorsTable_tableName'}
					id={'contractorsTable ContractorsTable_tableName'}
					columns={columns}
					data={unarchivedContractorData}
					row={(contractor, i) => <ContractorRow handlers={handlers} key={contractor._id + 'contractorRow'} contractor={contractor} />}
					scrollable
					withPagination
				/> :
				<TableError>No contractors data.</TableError>
		}
		{
			archivedContractorData && archivedContractorData.length > 0 ?
				<UniversalTable
					name={'ContractorsTable_tableNameArchived'}
					id={'contractorsTable ContractorsTable_tableNameArchived'}
					columns={columns}
					data={archivedContractorData}
					row={(contractor, i) => <ContractorRow handlers={handlers} key={contractor._id + 'contractorArchivedRow'} contractor={contractor} />}
					scrollable
					withPagination
				/> : null
		}
	</>
}

export default ContractorsTable;
