import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { InputFormGroup, SingleselectFormGroup } from 'components/FormGroups';
import { FaEdit } from 'react-icons/fa'
import { Row, Col } from 'react-bootstrap';
import {
	useUpdateContractorMutation,
	useGetUnarchivedContractorTypesQuery,
	useGetUnarchivedContractorClassesQuery,
} from '../../../../../redux/slices/financeDictionaries';
import SelectOption from '../../../../../types/selectOption';
import { RolesEnum } from '../../../../../types/roles';
import { Contractor } from '../../../../../types/finance/contractor'
import { FaList, FaPenToSquare } from 'react-icons/fa6'
import UpdateModal from '../../../../../components/Modals/UpdateModal'

interface UpdateContractorModalProps {
	contractor: Contractor;
}

const UpdateContractorModal = ({ contractor }: UpdateContractorModalProps) => {
	const { t, i18n } = useTranslation();

	const [name, setName] = useState(contractor.name || '');
	const [fullName, setFullName] = useState(contractor.fullName || '');
	const [taxCode, setTaxCode] = useState(contractor.taxCode || '')
	const [registrationAddress, setRegistrationAddress] = useState(contractor.registrationAddress || '');

	const [contractorClass, setContractorClass] = useState<SelectOption | undefined>(
		{ label: (i18n.language === 'uk' ? contractor.class.ukName : contractor.class.enName), value: contractor.class._id, index: 0 }
	);
	const [type, setType] = useState<SelectOption | undefined>(
		{ label: (i18n.language === 'uk' ? contractor.type.ukName : contractor.type.enName), value: contractor.type._id, index: 0 }
	);

	const [nameError, setNameError] = useState(false);
	const [contractorClassError, setContractorClassError] = useState(false);
	const [typeError, setTypeError] = useState(false);

	const { data: contractorTypes = [] } = useGetUnarchivedContractorTypesQuery();
	const { data: contractorClasses = [] } = useGetUnarchivedContractorClassesQuery();
	const [updateContractor] = useUpdateContractorMutation();

	const handleUpdateContractor = async () => {
		let isValid = true;

		// Set error flags based on validation
		setNameError(!name);
		setContractorClassError(!contractorClass)
		setTypeError(!type);

		if (!name || !type || !contractorClass) {
			isValid = false;
		}

		if (!isValid) return false;

		const selectedType = contractorTypes.find(conType => conType._id === type?.value);
		const selectedContractorClass = contractorClasses.find(conClass => conClass._id === contractorClass?.value);

		if (!selectedContractorClass || !selectedType) {
			console.error("Invalid selections. Please select valid class and type.");
			setContractorClassError(!selectedContractorClass)
			setTypeError(!selectedType);
			return false;
		}

		const changes = {
			name,
			type: selectedType._id,
			class: selectedContractorClass._id,
			fullName,
			taxCode,
			registrationAddress,
		};

		try {
			await updateContractor({id: contractor._id, changes: changes});
			clearForm();
			return true;
		} catch (error) {
			console.error("Error updating contractor:", error);
			return false;
		}
	};

	const clearForm = () => {
		setName('');
		setFullName('')
		setType(undefined);
		setContractorClass(undefined)
		setRegistrationAddress('')

		setNameError(false);
		setTypeError(false);
		setContractorClassError(false);
	};

	return (
		<UpdateModal
			id={contractor._id}
			title='UpdateContractorModal_title'
			onAccept={handleUpdateContractor}
			icon={FaEdit}
			requiredRoles={[RolesEnum.admin, RolesEnum.finances]}
		>
			<Row>
				<Col md={12}>
					<InputFormGroup
						name="name"
						label={t('contractorName')}
						value={name}
						onChange={(e) => {
							setName(e.target.value);
							setNameError(false);
						}}
						error={nameError}
					/>
				</Col>
			</Row>
			<Row>
				<Col md={6}>
					<SingleselectFormGroup
						label={t('contractorType')}
						options={
							contractorTypes.map((type, index) =>
								({ label: (i18n.language === 'uk' ? type.ukName : type.enName), value: type._id, index }))
						}
						value={type}
						onChange={(selected) => {
							setType(selected);
							setTypeError(false);
						}}
						error={typeError}
						prefix={<FaList className="w-100 h-100" />}
					/>
				</Col>
				<Col md={6}>
					<SingleselectFormGroup
						label={t('contractorClass')}
						options={
							contractorClasses.map((type, index) =>
								({ label: (i18n.language === 'uk' ? type.ukName : type.enName), value: type._id, index }))
						}
						value={contractorClass}
						onChange={(selected) => {
							setContractorClass(selected);
							setContractorClassError(false);
						}}
						error={contractorClassError}
						prefix={<FaPenToSquare className="w-100 h-100" />}
					/>
				</Col>
			</Row>

			<Row>
				<Col md={12}>
					<InputFormGroup
						name="beneficiaryName"
						label={t('fullName')}
						value={fullName}
						onChange={(e) => setFullName(e.target.value)}
					/>
				</Col>
			</Row>
			<Row>
				<Col md={12}>
					<InputFormGroup
						name="taxCode"
						label={t('taxCode')}
						value={taxCode}
						onChange={(e) => setTaxCode(e.target.value)}
					/>
				</Col>
			</Row>
			<Row>
				<Col md={12}>
					<InputFormGroup
						name="registrationAddress"
						label={t('registrationAddress')}
						value={registrationAddress}
						onChange={(e) => setRegistrationAddress(e.target.value)}
					/>
				</Col>
			</Row>
		</UpdateModal>
	);
};

export default UpdateContractorModal;
